import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/SignInSide")),
);
export const PasswordRecoveryPage = Loadable(
  lazy(() => import("../pages/auth/PasswordRecovery")),
);

export const HomePage = Loadable(lazy(() => import("../pages/home/HomePage")));
export const ProjectsPage = Loadable(
  lazy(() => import("../pages/projects/ProjectsPage")),
);
export const FormsPage = Loadable(
  lazy(() => import("../pages/forms/FormsPage")),
);
export const CustomersPage = Loadable(
  lazy(() => import("../pages/customers/CustomersPage")),
);
export const TeamPage = Loadable(lazy(() => import("../pages/team/TeamPage")));
export const RewardsPage = Loadable(
  lazy(() => import("../pages/rewards/RewardsPage")),
);
export const EarningsPage = Loadable(
  lazy(() => import("../pages/earnings/EarningsPage")),
);
export const PresentationsPage = Loadable(
  lazy(() => import("../pages/presentations/PresentationsPage")),
);
export const SettingsPage = Loadable(
  lazy(() => import("../pages/settings/SettingsPage")),
);

export const AdminPage = Loadable(
  lazy(() => import("../pages/admin/AdminPage")),
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
