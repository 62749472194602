// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_ADMIN = "/admin";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/password-recovery"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, "/home"),
  projects: path(ROOTS_DASHBOARD, "/projects"),
  sales: path(ROOTS_DASHBOARD, "/sales"),
  customers: path(ROOTS_DASHBOARD, "/customers"),
  team: path(ROOTS_DASHBOARD, "/team"),
  rewards: path(ROOTS_DASHBOARD, "/rewards"),
  earnings: path(ROOTS_DASHBOARD, "/earnings"),
  presentations: path(ROOTS_DASHBOARD, "/presentations"),
  settings: path(ROOTS_DASHBOARD, "/settings"),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
};
