import { Box, Divider, Drawer, List, Toolbar } from "@mui/material";
import { MainListItems, SecondaryListItems } from "./ListItems";

type MobileDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export default function MobileDrawer({ open, onClose }: MobileDrawerProps) {
  const drawer = (
    <div>
      <Toolbar>
        <Box
          component="img"
          src="/logo/you-logo.svg"
          sx={{ width: 60, height: 60, marginLeft: 1 }}
        />
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainListItems onClose={onClose} />
        <Divider sx={{ my: 1 }} />
        <SecondaryListItems onClose={onClose} />
      </List>
    </div>
  );

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
      }}
    >
      {drawer}
    </Drawer>
  );
}
