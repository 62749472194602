export enum ContactType {
  EXECUTIVE = "UC_JI3QO0",
  SILVER = "1",
  GOLD = "OTHER",
  PLATINUM = "PARTNER",
}

export type EmailAndPhone = {
  ID?: string;
  VALUE_TYPE: string;
  VALUE: string;
};

export type Contact = {
  ID: string;
  NAME: string;
  LAST_NAME?: string;
  PHONE?: EmailAndPhone[];
  EMAIL?: EmailAndPhone[];
  BIRTHDATE?: string;
  COMMENTS?: string;
  ADDRESS?: string;
  ADDRESS_2?: string;
  ADDRESS_CITY?: string;
  ADDRESS_PROVINCE?: string;
  ADDRESS_REGION?: string;
  ADDRESS_POSTAL_CODE?: string;
  ADDRESS_COUNTRY?: string;
};
