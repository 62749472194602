import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { MotionLazyContainer } from "./components/animate";
import Router from "./routes";
import { AuthProvider } from "./auth/JwtContext";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <MotionLazyContainer>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </MotionLazyContainer>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
