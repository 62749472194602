import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";

import { LinkProps, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material";

const ListItemButtonLink = styled(ListItemButton)<LinkProps>(({ theme }) => ({
  // ...here be styling
}));

type ListProps = {
  onClose?: () => void;
};

export const MainListItems = ({ onClose }: ListProps) => (
  <React.Fragment>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/home"
      onClick={onClose}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/projects"
      onClick={onClose}
    >
      <ListItemIcon>
        <HolidayVillageIcon />
      </ListItemIcon>
      <ListItemText primary="Projects" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/customers"
      onClick={onClose}
    >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/team"
      onClick={onClose}
    >
      <ListItemIcon>
        <Diversity3Icon />
      </ListItemIcon>
      <ListItemText primary="Team" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/rewards"
      onClick={onClose}
    >
      <ListItemIcon>
        <MilitaryTechIcon />
      </ListItemIcon>
      <ListItemText primary="Rewards" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/earnings"
      onClick={onClose}
    >
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Earnings" />
    </ListItemButtonLink>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/presentations"
      onClick={onClose}
    >
      <ListItemIcon>
        <PresentToAllIcon />
      </ListItemIcon>
      <ListItemText primary="Presentations" />
    </ListItemButtonLink>
  </React.Fragment>
);

export const SecondaryListItems = ({ onClose }: ListProps) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Account
    </ListSubheader>
    <ListItemButtonLink
      LinkComponent={RouterLink}
      to="/dashboard/settings"
      onClick={onClose}
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </ListItemButtonLink>
  </React.Fragment>
);
